import Head from 'next/head'
import { Header } from '@/app/components/Header'
import { Footer } from '@/app/components/Footer'
import {
  CourseFiltersModal,
  CoursesFilterRow,
  CoursesHeader,
  CoursesList,
} from '../components'
import { ICourse } from '@/types/course'
import { IApiIndividualResponse, PaginatedQueryResponse } from '@/types/api'
import { ICategory } from '@/types/category'
import { useCourses } from '@/app/hooks/data-hooks/useCourses'
import { useState } from 'react'
import { useRouter } from 'next/router'
import { useTranslations } from 'next-intl'
import { NoCoursesMessage } from '../components/NoCoursesMessage'
import { Button } from '../../shared/components'
import { InfiniteData } from 'react-query'
import { MAX_COURSE_FILTER_PRICE } from '@/app/lib/constants'
import { ContractType } from '@/types/agreement'
import { useCategories } from '@/app/hooks/data-hooks/useCategories'
import { SearchInputProvider } from '@/app/modules/course/contexts/InputSearchContext'
import { MdOutlineTune } from 'react-icons/md'

interface CoursesPageProps {
  recommendations: IApiIndividualResponse<{
    courses: ICourse[]
    categories: ICategory[]
  }>
  initialCoursesResponse: InfiniteData<PaginatedQueryResponse<ICourse>>
}

export const CoursesPage = ({
  recommendations,
  initialCoursesResponse,
}: CoursesPageProps) => {
  const router = useRouter()
  const t = useTranslations('Courses')
  const [term, setTerm] = useState('')

  const { data: { courses: recommendedCourses = [] } = {} } = recommendations

  const { data: { data: categories = [] } = {} } = useCategories(
    {},
    { limit: 500 },
    { retry: false }
  )

  const [isCourseFiltersModalOpen, setCourseFiltersModalOpen] = useState(false)

  const {
    data: { pages: pages = [] } = {},
    fetchNextPage: loadMore,
    hasNextPage: canLoadMore,
    isLoading: isLoadingAllCourses,
    isRefetching: isRefetchingAllCourses,
  } = useCourses(
    {},
    { limit: 8 },
    { qualityScore: 'desc', random: 'asc' },
    { retry: false, initialData: initialCoursesResponse }
  )

  const filteredCourses = pages.map(({ data }) => data).flat()

  const totalCourses = pages[0]?.metadata.total ?? 0
  const coursesSectionTitle = t('all_courses_in_catalog')

  const clearFilters = () => {
    router.push(router.pathname)
  }

  const redirectToSearchPage = async (data: any) => {
    const mergedData = { ...data }
    if (term) {
      mergedData.term = term
    }
    
    const query = Object.entries(mergedData).reduce(
      (acc, [key, value]) => {
        if (Array.isArray(value) && value.length > 0) {
          return { ...acc, [key]: value.map((v) => v.id) }
        }

        if (value?.id) {
          return { ...acc, [key]: value.id }
        }

        if (key === 'budget') {
          return {
            ...acc,
            ...(value.min && { minPrice: value.min }),
            ...(value.max &&
              value.max !== MAX_COURSE_FILTER_PRICE && {
                maxPrice: value.max,
              }),
          }
        }

        if (
          key === 'agreementType' &&
          Object.values(ContractType).includes(value)
        ) {
          return {
            ...acc,
            [key]: value,
          }
        }

        if (typeof value === 'string' && value.trim()) {
          return { ...acc, [key]: value.trim() }
        }

        return acc
      },
      {}
    )
    await router.push({ pathname: '/search', query })
  }

  const onSearchHandler = async (term: string) => {
    await redirectToSearchPage({ term })
  }

  return (
    <>
      <Head>
        <title>{t('title')}</title>
      </Head>
      <SearchInputProvider>
        <Header />
        <main>
          <div className="flex flex-col-reverse sm:flex-col">
            <CoursesHeader
              onFilter={(term) => {
                setCourseFiltersModalOpen(true)
                setTerm(term)
              }}
              className="container"
              onSearch={onSearchHandler}
            />
            <CoursesFilterRow
              categories={[...categories].sort(() => Math.random() - 0.5)}
              className="container"
              wrapperProps={{ className: 'hidden md:flex' }}
            />
          </div>
          <div className="flex flex-col gap-10 my-10 sm:gap-14 sm:my-14">
            {totalCourses === 0 && !isLoadingAllCourses && (
              <NoCoursesMessage onClearFiltersClick={clearFilters} />
            )}
            <CoursesList
              title={
                <SectionTitleWithFiltersButton
                  sectionTitle={coursesSectionTitle}
                  onOpenFiltersModal={() => setCourseFiltersModalOpen(true)}
                />
              }
              courses={filteredCourses}
              loadMore={canLoadMore ? loadMore : undefined}
              className="container"
              isLoading={isLoadingAllCourses || isRefetchingAllCourses}
            />
            <CoursesList
              title={t('tailored_courses')}
              courses={recommendedCourses}
              className="container"
            />
          </div>
        </main>
        <Footer />
        <CourseFiltersModal
          hideOnClear
          open={isCourseFiltersModalOpen}
          onClose={() => setCourseFiltersModalOpen(false)}
          onSubmit={redirectToSearchPage}
        />
      </SearchInputProvider>
    </>
  )
}

function SectionTitleWithFiltersButton({ sectionTitle, onOpenFiltersModal }) {
  const t = useTranslations('Courses')

  return (
    <div className="flex">
      {sectionTitle}
      <Button
        color="white"
        size="small"
        onClick={onOpenFiltersModal}
        className="ml-auto"
      >
        <div className="flex flex-row gap-2 items-center">
          <MdOutlineTune className="w-4 h-4" />
          <span className="hidden text-sm sm:block">{t('filters')}</span>
        </div>
      </Button>
    </div>
  )
}
